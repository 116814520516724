import { Toast } from "bootstrap";

export function showSpinner(spinnerId, replaceClass = '') {
    const spinner = document.querySelector(spinnerId);

    if (spinner)
        spinner.classList.remove('d-none');

    if (replaceClass != '')
        spinner.classList.add(replaceClass);
}

export function hideSpinner(spinnerId, replaceClass = '') {
    const spinner = document.querySelector(spinnerId);

    if (spinner)
        spinner.classList.add('d-none');

    if (replaceClass != '')
        spinner.classList.remove(replaceClass);
}

export function showToast(obj = { text: '', class: 'bg-success' }) {
    const toast = document.querySelector('#message-toast');
    const toastBody = toast.querySelector('.toast-body');

    toastBody.textContent = obj.text;
    toast.classList.add(obj.class);

    const toastBoot = new Toast(toast);
    toastBoot.show();
}