import axios from 'axios';
import { Toast } from 'bootstrap';

const addToCartButton = document.querySelector('#add-to-cart');
const removeCartButton = document.querySelector('#remove-cart-button');

const endpoints = {
    getCart: '/cart/get',
    addCartEntry: '/cart/entry',
    removeCart: '/cart/remove',
    updateCart: '/cart/entry/update',
    removeCartItem: '/cart/removeitem'
};

getCart();

if (removeCartButton) {
    removeCartButton.addEventListener('click', function () {
        const cartId = getCartCookieValue();

        removeCart(cartId);
    });
}

if (addToCartButton) {
    const addToCartValue = document.querySelector('#add-to-cart-quantity');
    addToCartValue.addEventListener('input', function () {
        const val = this.value;
        if (val === '') {
            this.value = 1;
        }

        if (isNaN(val)) {
            this.value = 1;
            return;
        }

        const parsed = parseInt(val);

        if (parsed <= 0) {
            this.value = 1;
            return;
        }
    });

    addToCartButton.addEventListener('click', function () {
        const productId = this.dataset.product;
        const quantity = addToCartValue.value;
        const cartId = getCartCookieValue();

        if (quantity <= 0) {
            return;
        }

        addCartEntry(quantity, productId, cartId, this);
    });
}

function getCartToUi(data) {
    const cart = data;
    const cartItemIndicator = document.querySelector('#cart-item-indicator');
    const cartEntriesLength = cart.cartEntries.length;

    cartItemIndicator.textContent = cartEntriesLength;

    const cartEmptyIndicator = document.querySelector('#cart-block-empty-indicator');
    const cartBlock = document.querySelector('#cart-block');

    if (cartEntriesLength === 0) {
        document.querySelector('#empty-cart-text').classList.remove('d-none');
        document.querySelector('#mini-cart-items').innerHTML = '';
        document.querySelector('#mini-cart-header').classList.add('d-none');

        if (cartBlock) {
            cartBlock.classList.add('d-none');
        }

        if (cartEmptyIndicator) {
            cartEmptyIndicator.classList.remove('d-none');
        }

        return;
    }

    if (cartBlock) {
        cartBlock.classList.remove('d-none');
    }

    if (cartEmptyIndicator) {
        cartEmptyIndicator.classList.add('d-none');
    }

    document.querySelector('#mini-cart-header').classList.remove('d-none');
    document.querySelector('#empty-cart-text').classList.add('d-none');

    let obj = '';

    for (let i in cart.cartEntries) {
        const item = cart.cartEntries[i];
        obj += `<div class="cart-item d-flex justify-content-center align-items-center">
                            <div class="cart-item-image cart-item-block">
                                <img src="${item.cartEntryImage.thumbnailUrl}" alt="${item.cartEntryItem[0].name}" />
                            </div>
                            <div class="cart-item-title cart-item-block">
                                <h4>${item.cartEntryItem[0].name}</h4>
                            </div>
                            <div class="cart-item-options cart-item-block">
                                <div class="d-flex justify-content-center">
                                    <input class="form-control p-1 border-secondary ms-1 me-1 quantity-input" value="${item.quantity}"
                                        disabled />
                                </div>
                            </div>
                            <div class="cart-item-price cart-item-block rm-md">
                                
                                <span class="price">
                                    ${item.cartEntryPrice.valueFormatted}
                                </span>
                                <span class="currency">
                                    RSD
                                </span>
                                
                            </div>
                            <div class="cart-item-price cart-item-block">
                                <span class="price">
                                    ${item.cartEntryPrice.valuePlusQuantityFormatted}
                                </span>
                                <span class="currency">
                                    RSD
                                </span>
                            </div>
                            <div class="cart-item-actions cart-item-block">
                                <button id="mini-cart-item-remove-${item.id}" data-cartentry="${item.id}" class="btn text-danger  p-1 remove-cart-item">
                                    <i class="bi bi-trash"></i>
                                </button>
                                <div id="remove-from-cart-spinner-${item.id}" class="spinner-border spinner-border-sm d-none" role="status">
                                    <span class="visually-hidden">Loading...</span>
                                </div>

                            </div>
                        </div>`
    }

    document.querySelector('#mini-cart-items').innerHTML = obj;

    if (cartBlock && cartEntriesLength > 0) {
        let entriesHtml = '';
        //console.

        for (let i in cart.cartEntries) {
            const item = cart.cartEntries[i];
            entriesHtml += `
                    <div
                    class="cart-content d-flex justify-content-center align-items-center pt-2 pb-2 text-center border-bottom border-dark">

                    <div class="cart-item">
                        <img style="max-width: 50%;" src="${item.cartEntryImage.thumbnailUrl}" alt="${item.cartEntryItem[0].name}" />
                    </div>
                    <div class="cart-item">
                        ${item.cartEntryItem[0].name}
                    </div>
                    <div class="cart-item cart-control">
                        <div class="d-flex justify-content-center">
                            <button class="btn btn-outline-secondary btn-sm btn-decrement" data-entry="${item.id}">
                                -
                            </button>
                            <input id="quantity-input-${item.id}" class="form-control border-secondary ms-1 me-1 cart-quantity-input" value="${item.quantity}" />
                            <button class="btn btn-outline-secondary btn-sm btn-increment" data-entry="${item.id}">
                                +
                            </button>
                        </div>
                    </div>
                    <div class="cart-item cart-price">
                        <span class="price">
                            ${item.cartEntryPrice.valueFormatted}
                        </span>
                        <span class="currency">
                            RSD
                        </span>
                    </div>
                    <div class="cart-item cart-price">
                        <span class="price">
                            ${item.cartEntryPrice.valuePlusQuantityFormatted}
                        </span>
                        <span class="currency">
                            RSD
                        </span>
                    </div>
                    <div class="cart-item">
                        <button id="cart-item-remove-${item.id}" class="btn btn-outline-danger btn-sm remove-cart-item" data-cartentry="${item.id}">
                            <i class="bi bi-trash"></i>
                        </button>
                        <div id="remove-from-cart-main-spinner-${item.id}" class="spinner-border spinner-border-sm d-none" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </div>
                </div>`;
        }

        document.querySelector('.cart-content-wrap').innerHTML = entriesHtml;
        document.querySelector('#cart-price').textContent = cart.cartPrice.valueFormatted;
    }

    const removeCartItemButtons = document.querySelectorAll('.remove-cart-item');
    removeCartItemButtons.forEach(item => {
        item.addEventListener('click', function (e) {
            e.stopPropagation();
            const cartId = getCartCookieValue();
            const cartEntryId = this.dataset.cartentry;
            removeCartEntry(cartId, cartEntryId);
        });
    });

    const decrementCartButtons = document.querySelectorAll('.btn-decrement');
    const incrementCartButtons = document.querySelectorAll('.btn-increment');

    if (decrementCartButtons.length > 0) {
        decrementCartButtons.forEach(item => {
            item.addEventListener('click', function (e) {
                const cartId = getCartCookieValue();
                const entryId = this.dataset.entry;
                const quantityField = `#quantity-input-${entryId}`;

                const input = document.querySelector(quantityField);
                const quantity = parseInt(input.value);
                updateCart(cartId, entryId, quantity - 1);
            });
        });
    }

    if (incrementCartButtons.length > 0) {
        incrementCartButtons.forEach(item => {
            item.addEventListener('click', function (e) {
                const cartId = getCartCookieValue();
                const entryId = this.dataset.entry;
                const quantityField = `#quantity-input-${entryId}`;

                const input = document.querySelector(quantityField);
                const quantity = parseInt(input.value);
                updateCart(cartId, entryId, quantity + 1);
            });
        });
    }
}


function getCart() {
    var cartId = getCartCookieValue();
    var endpoint = endpoints.getCart + "/" + cartId;

    axios.get(endpoint, {
        responseType: 'json'
    })
        .then(data => {
            getCartToUi(data.data);
        })
        .catch(ex => {
            console.log(ex);
        });
}

function addCartEntry(quantity, productId, cartId, element) {
    var data = {
        quantity: parseInt(quantity),
        productId: parseInt(productId),
        cartId: parseInt(cartId)
    }

    showSpinner('#add-to-cart-spinner');
    hideIcon('#spinner-border-icon');

    axios.post(endpoints.addCartEntry, {
        ...data,
    })
        .then(data => {
            data = data.data;
            getCart();
            hideSpinner('#add-to-cart-spinner');
            showIcon('#spinner-border-icon');

            if (data.isError) {
                showToast({ text: data.text, class: 'bg-danger' });
            } else {
                showToast({ text: data.text, class: 'bg-success' });
            }
        })
        .catch((ex) => {
            hideSpinner('#add-to-cart-spinner');
            showIcon('#spinner-border-icon');

            if (ex.response) {
                showToast({ text: ex.response.data.Text, class: 'bg-danger' });
            }
        });
}

function removeCartEntry(cartId, entryId) {
    const spinnerId = `#remove-from-cart-spinner-${entryId}`;
    const iconId = `#mini-cart-item-remove-${entryId}`;
    const mainCartId = `#remove-from-cart-main-spinner-${entryId}`;
    const mainIconId = `#cart-item-remove-${entryId}`;

    showSpinner(mainCartId);
    hideIcon(mainIconId);
    showSpinner(spinnerId);
    hideIcon(iconId);
    axios.delete(`${endpoints.removeCartItem}?cartId=${cartId}&cartEntryId=${entryId}`)
        .then(data => {
            data = data.data;
            getCart();
            hideSpinner(spinnerId);
            hideSpinner(mainCartId);

            if (data.isError) {
                showToast({ text: data.text, class: 'bg-danger' });
            } else {
                showToast({ text: data.text, class: 'bg-success' });
            }
        })
        .catch(ex => {
            hideSpinner(spinnerId);
            hideSpinner(spinnerId);
            showIcon(iconId);
            showIcon(mainIconId);

            if (ex.response) {
                showToast({ text: ex.response.data.Text, class: 'bg-danger' });
            }
        });
}

function removeCart(cartId) {
    const spinner = '#remove-cart-spinner';

    showSpinner(spinner);
    axios.delete(`${endpoints.removeCart}?cartId=${cartId}`)
        .then(data => {
            data = data.data;
            getCart();
            hideSpinner(spinner);

            if (data.isError) {
                showToast({ text: data.text, class: 'bg-danger' });
            } else {
                showToast({ text: data.text, class: 'bg-success' });
            }
        })
        .catch(ex => {
            hideSpinner(spinner);
            if (ex.response) {
                showToast({ text: ex.response.data.Text, class: 'bg-danger' });
            }
        })
}

function updateCart(cartId, entryId, quantity) {
    const data = {
        cartId,
        entryId,
        quantity
    };

    showSpinner('#global-cart-spinner-wrapper', 'd-flex');
    axios.put(endpoints.updateCart, data, {
        headers: {
            'Content-Type': 'application/json',
        }
    })
        .then(data => {
            getCart();
            hideSpinner('#global-cart-spinner-wrapper', 'd-flex');
        })
        .catch(ex => {
            hideSpinner('#global-cart-spinner-wrapper', 'd-flex');
        });
}

function getCartCookieValue() {
    const cartCookieName = "Cart=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');

    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];

        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }

        if (c.indexOf(cartCookieName) == 0) {
            return c.substring(cartCookieName.length, c.length);
        }
    }

    return '';
}

function showToast(obj = { text: '', class: 'bg-success' }) {
    const toast = document.querySelector('#message-toast');
    const toastBody = toast.querySelector('.toast-body');

    toastBody.textContent = obj.text;
    toast.classList.add(obj.class);

    const toastBoot = new Toast(toast);
    toastBoot.show();
}

function showSpinner(spinnerId, replaceClass = '') {
    const spinner = document.querySelector(spinnerId);

    if (spinner)
        spinner.classList.remove('d-none');

    if (replaceClass != '')
        spinner.classList.add(replaceClass);
}

function hideSpinner(spinnerId, replaceClass = '') {
    const spinner = document.querySelector(spinnerId);

    if (spinner)
        spinner.classList.add('d-none');

    if (replaceClass != '')
        spinner.classList.remove(replaceClass);
}

function showIcon(iconId) {
    const icon = document.querySelector(iconId);

    if (icon)
        icon.classList.remove('d-none');
}

function hideIcon(iconId) {
    const icon = document.querySelector(iconId);

    if (icon)
        icon.classList.add('d-none');
}