const sm = window.matchMedia('(min-width: 576px)');
const md = window.matchMedia('(min-width: 768px)');
const lg = window.matchMedia('(min-width: 992px)');
const xl = window.matchMedia('(min-width: 1400px)');

if (matchMedia) {
    sm.addListener(widthChange);
    md.addListener(widthChange);
    lg.addListener(widthChange);
    xl.addListener(widthChange);
    widthChange();
}

// media query change
function widthChange() {
    if (xl.matches) {
        changeBackgroundBg('xl');
        changeBackground('xl');
    }
    else if (lg.matches) {
        changeBackgroundBg('lg');
        changeBackground('lg');
    }
    else if (md.matches) {
        changeBackgroundBg('md');
        changeBackground('md');
    }
    else if (sm.matches) {
        changeBackgroundBg('sm');
        changeBackground('sm');
    }
    else {
        changeBackgroundBg('xs');
        changeBackground('xs');
    }
}

function changeBackground(param) {
    const elements = document.getElementsByClassName('image-responsive-media');
    for (let i = 0; i < elements.length; i++) {
        let att = elements[i].getAttribute('data-img' + param);
        elements[i].src = att;
        // elements[i].style.backgroundImage = 'url(' + att + ')';
        // elements[i].style.backgroundRepeat = 'no-repeat';
        // elements[i].style.backgroundSize = 'cover';
    }
}


function changeBackgroundBg(param) {
    const elements = document.getElementsByClassName('image-responsive-media-bg');
    for (let i = 0; i < elements.length; i++) {
        let att = elements[i].getAttribute('data-img' + param);
        elements[i].style.backgroundImage = `url('${att}')`;
        // elements[i].style.backgroundImage = 'url(' + att + ')';
        // elements[i].style.backgroundRepeat = 'no-repeat';
        // elements[i].style.backgroundSize = 'cover';
    }
}