let isModalShown = false;

const ageOfBirthField = document.querySelector('#AgeOfBirth');
const saveButton = document.querySelector('#minority-modal-save');
const redirectButton = document.querySelector('#minority-modal-redirect');
const minorityForm = document.querySelector('.minority-form');
const minorityNotice = document.querySelector('.minority-notice');
const minorityNoticeTitle = document.querySelector('#minority-notice-title');
const minorityNoticeTitleUnderage = document.querySelector('#minority-notice-title-underage');
const body = document.querySelector('body');
const minorityModal = document.querySelector('.minority-check');

setTimeout(function () {
    if (!isModalShown) {
        openModal();
    }
}, 2000);

setInterval(function () {
    if (!isModalShown) {
        openModal();
    }
}, 10000);

function openModal() {
    var cookieValue = getCartCookieValue();
    if (cookieValue === '') {
        isModalShown = true;
    } else {
        const val = parseInt(cookieValue);

        if (val < 18) {
            minorityForm.classList.add('d-none');
            minorityNotice.classList.remove('d-none');
            isModalShown = true;
        } else {
            isModalShown = false;
        }
    }
    setBodyOverflow(!isModalShown);
    showModal(isModalShown);
}

document.querySelector('#minority-modal-yes').addEventListener('click', function() {
    createCookie('yes');
    setBodyOverflow(true);
    showModal(false);
});

document.querySelector('#minority-modal-no').addEventListener('click', function() {
    history.back();
});

/*saveButton.addEventListener('click', function () {
    const age = getValues();

    if (age == null)
        return;

    const date = new Date();
    date.setFullYear(age);

    const currentDate = new Date();

    const year = currentDate.getFullYear() - date.getFullYear();
    createCookie(year);

    if (year < 18) {
        minorityForm.classList.add('d-none');
        minorityNotice.classList.remove('d-none');
        minorityNoticeTitle.classList.add('d-none');
        minorityNoticeTitleUnderage.classList.remove('d-none');
    } else {
        isModalShown = false;
    }

    setBodyOverflow(!isModalShown);
    showModal(isModalShown);
});*/

function createCookie(value) {
    sessionStorage.setItem('Minority', value);
}

function getValues() {

    let ageOfBirthFieldVal = ageOfBirthField.value;

    if (isNaN(ageOfBirthFieldVal)) {
        ageOfBirthField.classList.add('border-danger');
        return null;
    }

    return ageOfBirthFieldVal;
}

function getCartCookieValue() {
    //return '';
    const localStorageItemName = 'Minority';

    if (sessionStorage.getItem(localStorageItemName)) {
        return sessionStorage.getItem(localStorageItemName);
    }

    return '';
}

function setBodyOverflow(isShown = true) {
    if (!isShown) {
        body.style.overflowY = 'hidden';
        return;
    }

    body.style.overflowY = '';
}

function showModal(isShown = true) {
    if (!isShown) {
        minorityModal.classList.add('d-none');
        minorityModal.classList.remove('d-flex');
        return;
    }

    minorityModal.classList.remove('d-none');
    minorityModal.classList.add('d-flex');
}