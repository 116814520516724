import axios from "axios";
import { showSpinner, hideSpinner, showToast } from "../utils/utils";

const orderForm = document.querySelector('#order-form');

if (orderForm) {
    orderForm.addEventListener('submit', function (e) {
        e.preventDefault();

        const formData = new FormData(this);

        const requestData = {};

        for (let [key, value] of formData) {
            requestData[key] = value;
        }

        console.log(requestData);

        showSpinner('#order-spinner', 'd-flex');
        axios.post('/checkout/validate', { ...requestData })
            .then(data => {
                hideSpinner('#order-spinner', 'd-flex');
                this.submit();
            })
            .catch(ex => {
                if (ex.response) {
                    console.log(ex.response);
                    showToast({ text: ex.response.data.Text, class: 'bg-danger' });
                }
                hideSpinner('#order-spinner', 'd-flex');
            });
    });
}