const productBlock = document.querySelector('.product-info');

if (productBlock) {
    // let elementHeight = productBlock.clientHeight;

    // const imageResponseMediaInProduct = document.querySelectorAll('.carousel-item .image-responsive-media');

    // imageResponseMediaInProduct.forEach(x => {
    //     x.style.height = `${elementHeight}px`;
    // });
}
